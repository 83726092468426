<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <!-- <span class="brand-logo"> -->
          <span class="brand-logo-collect">
            <b-img
              :src="appLogoImage"
              alt="logo"
              class="logo-img-space"
            />
          </span>
          <!-- <div class="">
            <div class="subtitle">
              働きたい！が必ず見つかる
            </div> -->
          <!-- <h2 class="brand-text mb-0"> -->
          <!-- <h2 class="brand-text-collect">
              {{ appName }}
            </h2>
          </div> -->
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style scoped>
.brand-logo-collect {
  height: 10px;
}
.logo-img-space {
  height: 30px;
  margin: -5px 0 0 0;
}
.subtitle {
  font-size: 0.1rem;
  margin: 0 0 0 15px;
}
.brand-text-collect {
  font-size: 1.5rem;
  margin: 0 0 0 15px;
}
</style>
