<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navItem" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import adminHorizontal from '@/navigation/admin/horizontal'
import store from '@/store'
import { ref, onMounted } from '@vue/composition-api'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    const navItem = ref(navMenuItems)

    onMounted(() => {
      if (store.state.userBasic.userType === 'collect') navItem.value = adminHorizontal
    })
    return {
      navItem,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
