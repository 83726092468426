export default [
  {
    title: 'ダッシュボード',
    route: 'dashboad',
    icon: 'HomeIcon',
  },
  {
    title: 'プロフィール',
    route: 'profile',
    icon: 'FileTextIcon',
  },
  {
    title: 'アンケート',
    route: 'hearing',
    icon: 'EditIcon',
  },
  // {
  //   title: '求人情報一覧',
  //   route: 'joblist',
  //   icon: '',
  // },
  // {
  //   title: '応募履歴',
  //   route: 'applylist',
  //   icon: '',
  // },
  {
    title: 'プライバシーポリシー',
    icon: 'BookIcon',
    route: 'app-privacy',
  },
  {
    title: '利用規約',
    icon: 'BookOpenIcon',
    route: 'app-term',
  },
  // {
  //   header: '確認用',
  //   title: '一般',
  //   icon: 'FileTextIcon',
  //   children: [
  //     {
  //       title: 'トップ',
  //       route: 'top',
  //     },
  //     {
  //       title: '求人情報一覧',
  //       route: 'jobs',
  //     },
  //     {
  //       title: 'ログイン',
  //       route: 'jplogin',
  //     },
  //     {
  //       title: 'アカウント作成',
  //       route: { path: '/sendmail', query: { type: 'student' } },
  //     },
  //   ],
  // },
]
